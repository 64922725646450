import { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

import { AuthContext } from "./AuthContext";
import { RestaurantContext } from "./RestaurantContext";

import apiService from "../services/apiService";

import FindRestaurantForm from "./FindRestaurant/FindRestaurantForm";
import GoogleRestaurantResult from "./FindRestaurant/GoogleRestaurantResult";
import DashboardLoader from "./DashboardLoader";
import Modal from "./Modal";

import useInterval from "../hooks/useInterval";

const UserFindRestaurant = ({ user, onSubmit }) => {
  return (
    <div className='flex flex-col items-center justify-center flex-grow min-w-full p-[16px] md:p-[50px] md:pl-[104px]'>
      <h1 className='text-center text-[32px] font-semibold text-white mb-[12px]'>Find Restaurant</h1>
      <p className='text-center text-[18px] font-medium text-white mb-[12px]'>Find your restaurant to get started.</p>
      {user?.subscription?.status === 'trialing' && (
        <p className="text-sm italic text-white text-center">
          Trial accounts are limited to 100 reviews per network.
        </p>
      )}

      <div className="mt-[30px]">
        <FindRestaurantForm onSubmit={onSubmit} />
      </div>
    </div>
  )
}

const FindRestaurant = () => {
  const { user } = useContext(AuthContext);
  const { restaurant, isLoadingRestaurant, setRestaurant, setUserRestaurant, setIsFindingRestaurant } = useContext(RestaurantContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [loadingSubtitle, setLoadingSubtitle] = useState(null);;

  const [foundRestaurant, setFoundRestaurant] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useInterval(() => {
    const fetchRestaurant = async () => {
      const { data: restaurant } = await apiService.fetchUserRestaurant({ userId: user._id });

      if (restaurant) {
        setRestaurant(restaurant);
        setUserRestaurant(restaurant);
        setIsFindingRestaurant(false);
        setLoading(false);
        setLoadingMessage(null);
        setHasSubmitted(false);
        navigate('/dashboard');
      }
    }

    fetchRestaurant();
  }, hasSubmitted ? 10 * 1000 : null);

  const handleSubmit = async (name, address) => {
    try {
      setLoading(true);
      setLoadingMessage('Finding your restaurant...');

      const response = await apiService.findRestaurant({ name, address });

      setFoundRestaurant(response.data.data.google[0]);
      setLoading(false);
      setLoadingMessage(null);
      setIsResultModalOpen(true);

    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An error occurred while finding the restaurant.';
      toast.error(errorMessage);
      setLoading(false);
      setLoadingMessage(null);
    }
  }

  const handleSearchAgainClicked = () => {
    setFoundRestaurant(null);
    setIsResultModalOpen(false);
  }

  const handleConfirmClicked = async () => {
    setIsResultModalOpen(false);
    setLoadingMessage('Starting analysis');
    setLoadingSubtitle('This may take a minute, please stay on this page.')
    setLoading(true);
    setIsFindingRestaurant(true);

    const response = await apiService.fetchRestaurantByGoogleSlug({ googleSlug: foundRestaurant.slug });
    const existingRestaurant = response?.data;

    if (existingRestaurant?.user) {
      setLoading(false);
      setLoadingMessage(null);
      setLoadingSubtitle(null);
      setIsFindingRestaurant(false);
      return toast.error('This restaurant has already been added. Please contact an administrator.');
    }

    await apiService.startRestaurantAnalysis({
      googleResult: foundRestaurant,
      userId: user._id
    });

    setHasSubmitted(true);
  }

  if (restaurant) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <Helmet>
        <title>Find Restaurant | Restaurant Report</title>
      </Helmet>

      {isLoadingRestaurant || loading
        ? <DashboardLoader message={loadingMessage} subtitle={loadingSubtitle} />
        : <UserFindRestaurant user={user} onSubmit={handleSubmit} />
      }

      <Modal isOpen={isResultModalOpen}>
        <GoogleRestaurantResult
          restaurant={foundRestaurant}
          onConfirmClicked={handleConfirmClicked}
          onCancelClicked={handleSearchAgainClicked}
        />
      </Modal>
    </>
  );
}

export default FindRestaurant;
