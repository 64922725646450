import { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';

import DashboardLoader from "./DashboardLoader";

import authService from '../services/authService';

import { APP_URL } from '../constants';

import { ReactComponent as GoogleLogo } from '../assets/google-icon.svg';

const LoginForm = () => {
  const { setToken } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleGoogleSignIn = async () => {
      try {
        if (!isSigningIn) {
          setIsSigningIn(true);

          const { data: { token } } = await authService.googleLoginUser({ code: searchParams.get('code') });

          localStorage.setItem('token', token);
          navigate('/dashboard');
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'An error occurred. Please try again.');
        setIsLoading(false);
      }
    }

    if (searchParams.get('code')) {
      if (!isSigningIn) {
        handleGoogleSignIn();
      }
    }

    if (!isSigningIn && !searchParams.get('code')) {
      setIsLoading(false);
    }
  }, [searchParams, isLoading, navigate, isSigningIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await authService.loginUser({ email, password });

      setToken(response.data.token);
      localStorage.setItem("token", response.data.token);

      navigate("/dashboard");
    } catch (error) {
      setToken(null);
      localStorage.removeItem("token");

      toast.error(error?.response?.data?.message || 'An error occurred. Please try again.');
    }
  }

  const handleGoogleSignInClicked = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const { data: { url } } = await authService.getGoogleAuthUrl({ redirectUri: `${APP_URL}/login` });

      window.location.href = url;
    } catch (error) {
      console.log(error);
      toast.error('An error occurred. Please try again.');
    }
  };

  if (isLoading) {
    return <DashboardLoader message='Signing in...' />;
  }

  return (
    <div className='flex flex-col items-center justify-between flex-grow min-h-screen p-[16px]'>
      <Helmet>
        <title>Login | Restaurant Report</title>
      </Helmet>

      <h1 className='text-[25px] font-semibold gap-1 flex items-center justify-center mt-[50px]'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </h1>

      <div className='flex flex-col items-center justify-center flex-grow min-w-full'>
        <h2 className='text-center text-[32px] font-medium text-white mb-[24px]'>Log In</h2>

        <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
          <label className='flex flex-col gap-[16px] mb-[24px]'>
            <span className='text-white font-medium text-[17px]'>Your E-mail</span>

            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
            />
          </label>

          <label className='flex flex-col gap-[16px] mb-[16px]'>
            <span className='text-white font-medium text-[17px]'>Password</span>

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className='bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light rounded-md'
            />
          </label>

          <div className='flex items-center justify-between mb-[32px]'>
            <label className='flex items-center'>
              <input type="checkbox" className='mr-[8px] bg-transparent border border-[#B9BECF] checked:bg-r-blue checked:focus:bg-r-blue focus:ring-0 rounded-sm hover:bg-r-blue checked:hover:bg-r-blue focus:outline-none hover:ring-0 focus:border-none' />

              <span className='text-[#929AA6] text-[16px]'>Remember me</span>
            </label>

            <Link to="/forgot-password" className='font-medium text-[#C0C7D1] text-[16px]'>
              Forgot password?
            </Link>
          </div>

          <button type="submit" disabled={!email || !password} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Log In</button>

          <button
            onClick={handleGoogleSignInClicked}
            className='bg-transparent border border-r-blue text-white font-semibold p-2 rounded-md text-center flex items-center justify-center gap-2 h-[56px] mb-[32px]'
          >
            <GoogleLogo /> Log In with Google
          </button>

          <p className='text-center text-[#A2A9BF] font-medium'>
            Don't have an account? <a href="https://www.restaurantreport.ai?section=pricing" className='text-r-blue'>Sign Up</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
