import { useState } from 'react';

import { ReactComponent as CheckmarkIcon } from '../../assets/new-green-checkmark.svg';
import { ReactComponent as PromoDesktop } from '../../assets/6-months-free-desktop.svg';
import { ReactComponent as PromoMobile } from '../../assets/6-months-free-mobile.svg';

import DoorDashIcon from '../../assets/new-doordash-logo.png';
import GoogleIcon from '../../assets/new-google-logo.png';
import GrubHubIcon from '../../assets/new-grubhub-logo.png';
import OpenTableIcon from '../../assets/new-opentable-logo.png';
import TripAdvisorIcon from '../../assets/new-tripadvisor-logo.png';

import FoodIcon from '../../assets/new-food-icon.svg';
import DrinksIcon from '../../assets/new-drinks-icon.svg';
import ServiceIcon from '../../assets/new-service-icon.svg';
import PriceIcon from '../../assets/new-price-icon.svg';

import userPlans from "../../data/userPlans";

const PlansNew = ({ onPlanClicked }) => {
  const [periodSelected, setPeriodSelected] = useState('yearly');

  const activePeriodClasses = "h-[36px] md:h-[38px] flex items-center justify-center text-[14px] leading-[22px] md:text-[16px] md:leading-[26px] font-bold rounded-md bg-white";
  const inactivePeriodClasses = "h-[36px] md:h-[38px] flex items-center justify-center text-[14px] leading-[22px] md:text-[16px] md:leading-[26px] rounded-md text-white";

  return (
    <section class="relative py-[20px] px-4 max-w-[1200px] mx-auto">
      <div
        class="mb-[62px] md:mb-[32px] grid grid-cols-2 relative w-[245px] mx-auto p-[5px] bg-white bg-opacity-[2%] rounded-md">
        <button
          onClick={() => { setPeriodSelected('monthly'); }}
          class={periodSelected === 'monthly' ? activePeriodClasses : inactivePeriodClasses}>
          Monthly
        </button>

        <button
          onClick={() => { setPeriodSelected('yearly'); }}
          class={periodSelected === 'yearly' ? activePeriodClasses : inactivePeriodClasses}>
          Yearly
        </button>

        <PromoMobile class="md:hidden absolute top-[13px] -right-[40px]" />
        <PromoDesktop class="hidden md:block absolute -top-[23px] -right-[150px]" />
      </div>

      <div
        class="md:mb-[32px] grid grid-cols-1 md:grid-cols-2 md:items-center md:w-[768px] md:justify-center md:mx-auto">
        <div
          class="flex flex-col flex-grow mb-6 p-5 md:p-6 bg-white bg-opacity-[2%] border border-white border-opacity-5 rounded-lg md:rounded-tr-none md:rounded-br-none">
          <p class="mb-2 text-[18px] leading-[26px] md:text-[20px] text-white font-semibold tracking-tight">
            Basic
          </p>

          <p class="mb-5 text-[32px] leading-[58px] md:text-[40px] font-bold tracking-tight text-white">
            ${periodSelected === 'monthly' ? userPlans[0].priceMonthly : userPlans[0].priceYearlyPerMonth}
            <span
              class="ml-[10px] text-[14px] leading-[26px] text-white text-opacity-60 font-normal tracking-normal">
              per month
            </span>
          </p>

          <button
            onClick={() => onPlanClicked(periodSelected === 'monthly' ? userPlans[0].idMonthly : userPlans[0].idYearly)}
            class="mb-4 rr-button-gradient text-white w-full text-[16px] leading-[20px] tracking-tight text-center font-bold rounded-md py-3">
            Select Plan
          </button>

          <div class="mb-5 h-[1px] bg-white bg-opacity-[8%]"></div>

          <ul class="flex flex-col gap-3">
            <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
              <CheckmarkIcon />
              <span>Restaurant Spy</span>
            </li>

            <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
              <CheckmarkIcon />
              <span>Advanced Search UI</span>
            </li>

            <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
              <CheckmarkIcon />
              <span>Keyword Search</span>
            </li>

            <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
              <CheckmarkIcon />
              <span>Browse All Reviews</span>
            </li>
          </ul>
        </div>

        <div class="flex flex-col flex-grow">
          <p class="px-2 w-fit rr-button-gradient text-white text-[16px] leading-[26px] font-medium rounded-t-md">
            The Most Popular
          </p>

          <div class="mb-6 p-5 md:p-6 rr-drop-gradient border border-[#36c5fe] rounded-lg rounded-tl-none">
            <p class="mb-2 text-[18px] leading-[26px] md:text-[20px] text-white font-semibold tracking-tight">
              Pro
            </p>

            <p class="mb-5 text-[32px] leading-[58px] md:text-[40px] font-bold tracking-tight text-white">
              ${periodSelected === 'monthly' ? userPlans[1].priceMonthly : userPlans[1].priceYearlyPerMonth}
              <span class="ml-[10px] text-[14px] leading-[26px] text-white text-opacity-60 font-normal tracking-normal">
                per month
              </span>
            </p>

            <button
              onClick={() => onPlanClicked(periodSelected === 'monthly' ? userPlans[1].idMonthly : userPlans[1].idYearly)}
              class="mb-4 rr-button-gradient text-white w-full text-[16px] leading-[20px] tracking-tight text-center font-bold rounded-md py-3">
              Select Plan
            </button>

            <div class="mb-5 h-[1px] bg-white bg-opacity-[8%]"></div>

            <ul class="flex flex-col gap-3">
              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>Restaurant Spy Advanced</span>
              </li>

              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>1 Free Advanced Spy Report included</span>
              </li>

              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>Advanced Search UI</span>
              </li>

              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>Keyword Search</span>
              </li>

              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>Browse All Reviews</span>
              </li>

              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>Find opportunities with AI</span>
              </li>

              <li class="flex items-center gap-2 text-[14px] leading-[20px] tracking-tight text-white">
                <CheckmarkIcon />
                <span>Q/A with AI</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <p class="mb-5 text-[16px] leading-[26px] tracking-tight font-medium text-white text-center">
        Discover customer insights across reviews from:
      </p>

      <div class="mb-6 flex items-center justify-center gap-4 flex-wrap">
        <img src={GoogleIcon} alt="Google" />
        <img src={GrubHubIcon} alt="GrubHub" />
        <img src={DoorDashIcon} alt="DoorDash" />
        <img src={TripAdvisorIcon} alt="TripAdvisor" />
        <img src={OpenTableIcon} alt="OpenTable" />
      </div>

      <p class="mb-3 text-[16px] leading-[26px] tracking-tight font-medium text-white text-center">
        Segment reviews by:
      </p>

      <div class="flex items-center justify-center gap-4 flex-wrap">
        <div class="flex items-center gap-1">
          <img src={FoodIcon} alt="Food" />
          <span class="text-[16px] leading-[26px] text-white tracking-tight font-medium">Food</span>
        </div>

        <div class="flex items-center gap-1">
          <img src={DrinksIcon} alt="Drinks" />
          <span class="text-[16px] leading-[26px] text-white tracking-tight font-medium">Drinks</span>
        </div>

        <div class="flex items-center gap-1">
          <img src={ServiceIcon} alt="Service" />
          <span class="text-[16px] leading-[26px] text-white tracking-tight font-medium">Service</span>
        </div>

        <div class="flex items-center gap-1">
          <img src={PriceIcon} alt="Price" />
          <span class="text-[16px] leading-[26px] text-white tracking-tight font-medium">Price</span>
        </div>
      </div>
    </section >
  );
};

export default PlansNew;
