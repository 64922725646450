import { useState } from "react";

import PlansNew from "./UserPlans/PlansNew";

import Modal from "./Modal";
import ChangePlanPrompt from "./UserPlans/ChangePlanPrompt";

const UpdatePlan = ({ onChangePlan, onCloseClicked }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [isChangePlanPromptOpen, setIsChangePlanPromptOpen] = useState(false);

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    setIsChangePlanPromptOpen(true);
  }

  const handlePlanChangeConfirmed = async () => {
    setIsChangePlanPromptOpen(false);
    onChangePlan(selectedPlan);
  }

  return (
    <div className="relative w-[1000px] max-h-[90%] bg-[#090A17] p-[32px] border border-[#36c5fe] rounded-lg overflow-scroll">
      <button
        onClick={onCloseClicked}
        class="absolute top-[20px] right-[20px] px-2 py-1 text-white text-[16px] leading-[26px] font-medium">
        X
      </button>

      <h1 className="mb-3 text-white text-xl font-bold text-center">
        Update your plan
      </h1>

      <PlansNew
        onPlanClicked={handleSelectPlan}
      />

      <Modal isOpen={isChangePlanPromptOpen}>
        <ChangePlanPrompt
          onConfirmClicked={handlePlanChangeConfirmed}
          onCancelClicked={e => setIsChangePlanPromptOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default UpdatePlan;
