import Loader from "./Loader";

function DashboardLoader({ message, subtitle }) {
  return (
    <div className="flex flex-col gap-2 items-center flex-grow justify-center">
      <Loader />

      {message && <p className="text-white text-lg font-semibold mt-4">{message}</p>}
      {subtitle && <p className="text-white text-sm italic font-semibold">{subtitle}</p>}
    </div>
  );
}

export default DashboardLoader;
