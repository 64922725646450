import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

import { AuthContext } from './AuthContext';
import { RestaurantContext } from './RestaurantContext';

import UserProfile from './Profile/UserProfile';
import Modal from './Modal';
import DashboardLoader from './DashboardLoader';
import CancelPlanPrompt from './UserPlans/CancelPlanPrompt';
import RevokeCancelationPrompt from './UserPlans/RevokeCancelationPrompt';
import apiService from '../services/apiService';
import DeleteProfilePrompt from './UserPlans/DeleteProfilePrompt';
import UpdatePlan from './UpdatePlan';

const Profile = () => {
  const { user, setUser, setToken, setHasFetchedUser } = useContext(AuthContext);
  const { setRestaurant } = useContext(RestaurantContext);

  const [isCancelPlanModalOpen, setIsCancelPlanModalOpen] = useState(false);
  const [isRevokeCancelationPromptOpen, setIsRevokeCancelationPromptOpen] = useState(false);
  const [isDeleteProfilePromptOpen, setIsDeleteProfilePromptOpen] = useState(false);
  const [isUpdatePlanModalOpen, setIsUpdatePlanModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const navigate = useNavigate();

  const revokeCancelation = async () => {
    try {
      setIsRevokeCancelationPromptOpen(false);
      setLoading(true);
      setLoadingMessage('Revoking cancelation...');

      await apiService.revokeSubscriptionCancelation({ userId: user._id, subscriptionId: user.subscription.id });

      const token = localStorage.getItem('token');
      const response = await apiService.fetchUserFromToken({ token });

      setUser(response.data);
      setLoading(false);
      setLoadingMessage(null);

      toast.success('Subscription cancelation revoked successfully');
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
      setLoadingMessage(null);
    }
  }

  const cancelSubscription = async () => {
    try {
      setIsCancelPlanModalOpen(false);
      setLoading(true);
      setLoadingMessage('Canceling subscription...');

      await apiService.cancelSubscription({ userId: user._id, subscriptionId: user.subscription.id });

      const token = localStorage.getItem('token');
      const response = await apiService.fetchUserFromToken({ token });

      setUser(response.data);
      setLoading(false);
      setLoadingMessage('Canceling subscription...');

      toast.success('Subscription canceled successfully');
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoadingMessage(null);
      setLoading(false);
    }
  }

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
    setHasFetchedUser(false);
    setRestaurant(null);
    navigate('/login');
  }

  const changePlan = async (priceId) => {
    setLoading(true);
    setLoadingMessage('Updating your plan...');
    setIsUpdatePlanModalOpen(false);

    if (user.subscription.status === 'trialing') {
      const response = await apiService.updateCanceledSubscription({
        userId: user._id,
        priceId
      });

      window.location.href = response.data.url;
    } else {
      await apiService.updateSubscription({
        userId: user._id,
        priceId
      });
    }

    const token = localStorage.getItem('token');
    const response = await apiService.fetchUserFromToken({ token });

    setUser(response.data);

    setLoading(false);
    setLoadingMessage(null);
  }

  const updateProfile = async ({ email, firstName, lastName }) => {
    setLoading(true);
    setLoadingMessage('Updating profile...');

    try {
      await apiService.updateUser({
        userId: user._id,
        updateData: {
          email,
          firstName,
          lastName
        }
      });

      toast.success('Profile updated successfully');
      setLoading(false);
      setLoadingMessage(null);
    } catch (error) {
      toast.error(error);
      setLoading(false);
      setLoadingMessage(null);
    }
  }

  const deleteProfile = async () => {
    try {
      setIsDeleteProfilePromptOpen(false);
      setLoading(true);
      setLoadingMessage('Deleting profile, please don\'t close the window...');

      await apiService.deleteUser({ userId: user._id });

      logout();
    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
      setLoadingMessage(null);
    }
  }

  return (
    <>
      <Helmet>
        <title>Profile | Restaurant Report</title>
      </Helmet>

      {loading || !user
        ? <DashboardLoader message={loadingMessage} />
        : <UserProfile
          user={user}
          onUpdateProfile={updateProfile}
          onLogoutClicked={logout}
          onRevokeCancelationClicked={() => setIsRevokeCancelationPromptOpen(true)}
          onUpdatePlanClicked={() => setIsUpdatePlanModalOpen(true)}
          onCancelPlanClicked={() => setIsCancelPlanModalOpen(true)}
          onDeleteProfileClicked={() => setIsDeleteProfilePromptOpen(true)}
        />
      }

      <Modal isOpen={isCancelPlanModalOpen}>
        <CancelPlanPrompt
          onConfirmClicked={cancelSubscription}
          onCancelClicked={() => setIsCancelPlanModalOpen(false)}
        />
      </Modal>

      <Modal isOpen={isRevokeCancelationPromptOpen}>
        <RevokeCancelationPrompt
          onConfirmClicked={revokeCancelation}
          onCancelClicked={() => setIsRevokeCancelationPromptOpen(false)}
        />
      </Modal>

      <Modal isOpen={isDeleteProfilePromptOpen}>
        <DeleteProfilePrompt
          onConfirmClicked={deleteProfile}
          onCancelClicked={() => setIsDeleteProfilePromptOpen(false)}
        />
      </Modal>

      <Modal isOpen={isUpdatePlanModalOpen}>
        <UpdatePlan
          onChangePlan={changePlan}
          onCloseClicked={() => setIsUpdatePlanModalOpen(false)}
        />
      </Modal>
    </>
  )
}

export default Profile;
