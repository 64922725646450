import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";

import { AuthContext } from "./AuthContext";
import { RestaurantContext } from "./RestaurantContext";

import apiService from "../services/apiService";

import GoogleRestaurantResult from "./FindRestaurant/GoogleRestaurantResult";
import DashboardLoader from "./DashboardLoader";
import Modal from "./Modal";

import useInterval from "../hooks/useInterval";
import SpyOnRestaurantForm from "./SpyOnRestaurantForm";

import { ReactComponent as SpyIcon } from '../assets/spy-icon.svg';

const UserFindRestaurant = ({ user, onSubmit }) => {
  return (
    <div className='flex flex-col items-center justify-center flex-grow min-w-full p-[16px] md:p-[50px] md:pl-[104px]'>
      <SpyIcon className='h-14 w-14 mb-5 stroke-r-blue fill-r-blue' />

      <h1 className='text-[32px] md:text-[48px] leading-[48px] md:leading-[56px] font-semibold mb-[16px] text-white font-outfit'>Spy on a restaurant</h1>

      <p className='text-center text-[18px] font-medium text-white mb-[10px]'>
        Find out what's working and what's holding your competitors back.
      </p>

      <i className="text-[14px] font-medium text-white mb-[12px]">
        All spy data is current as of today. Spy data will not update as new reviews or comments are posted.
      </i>

      <i className="text-[14px] font-medium text-white mb-[30px]">
        You will be directed to enter the restaurant's name and address after the checkout page.
      </i>

      <SpyOnRestaurantForm
        user={user}
        onSubmit={onSubmit}
      />
    </div>
  )
}

const RestaurantSpy = () => {
  const { user, setUser, token } = useContext(AuthContext);
  const { isLoadingRestaurant, setIsFindingRestaurant } = useContext(RestaurantContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [loadingSubtitle, setLoadingSubtitle] = useState(null);

  const [foundRestaurant, setFoundRestaurant] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useInterval(() => {
    const fetchUser = async () => {
      const userResponse = await apiService.fetchUserFromToken({ token });

      if (userResponse.data.creatingSnapshots?.length > 0 || !userResponse.data.creatingSnapshots) {
        setIsFindingRestaurant(false);
        setLoading(false);
        setLoadingMessage(null);
        setHasSubmitted(false);
        setUser(userResponse.data);
        navigate('/dashboard');
      }
    };

    fetchUser();
  }, hasSubmitted ? 5 * 1000 : null);

  const handleSubmit = async (name, address) => {
    try {
      setLoading(true);
      setLoadingMessage('Finding your restaurant...');

      const response = await apiService.findRestaurant({ name, address });

      setFoundRestaurant(response.data.data.google[0]);
      setLoading(false);
      setLoadingMessage(null);
      setIsResultModalOpen(true);

    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An error occurred while finding the restaurant.';
      toast.error(errorMessage);
      setLoading(false);
      setLoadingMessage(null);
    }
  }

  const handleSearchAgainClicked = () => {
    setFoundRestaurant(null);
    setIsResultModalOpen(false);
  }

  const handleConfirmClicked = async () => {
    setIsResultModalOpen(false);
    setIsFindingRestaurant(true);
    setLoadingMessage('Starting analysis');
    setLoadingSubtitle('This may take a minute, please stay on this page.');
    setLoading(true);

    await apiService.startRestaurantAnalysis({
      googleResult: foundRestaurant,
      userId: user._id,
      isSnapshot: true
    });

    const userResponse = await apiService.fetchUserFromToken({ token });
    setUser(userResponse.data);

    setHasSubmitted(true);
  }

  return (
    <>
      <Helmet>
        <title>Find Restaurant | Restaurant Report</title>
      </Helmet>

      {isLoadingRestaurant || loading
        ? <DashboardLoader message={loadingMessage} subtitle={loadingSubtitle} />
        : <UserFindRestaurant
          user={user}
          onSubmit={handleSubmit}
        />
      }

      <Modal isOpen={isResultModalOpen}>
        <GoogleRestaurantResult
          restaurant={foundRestaurant}
          onConfirmClicked={handleConfirmClicked}
          onCancelClicked={handleSearchAgainClicked}
        />
      </Modal>
    </>
  );
}

export default RestaurantSpy;
