import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from 'react-hot-toast';

import DashboardLoader from "./DashboardLoader";

import apiService from "../services/apiService";
import authService from "../services/authService";

import { APP_URL } from "../constants";

import { ReactComponent as GoogleLogo } from "../assets/google-icon.svg";

const RegistrationForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [checkoutId, setCheckoutId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isSigningUp, setIsSigningUp] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleGoogleSignup = async () => {
      try {
        if (!isSigningUp) {
          setIsSigningUp(true);

          const { data: { token } } = await authService.googleRegisterPaidUser({
            code: searchParams.get('code'),
            checkoutId: localStorage.getItem('checkoutId')
          });

          localStorage.setItem('token', token);
          navigate('/dashboard');
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || 'An error occurred. Please try again.');
        setIsLoading(false);
      }
    }

    if (!checkoutId) {
      if (searchParams.get('session_id')) {
        setCheckoutId(searchParams.get('session_id'));
        localStorage.setItem('checkoutId', searchParams.get('session_id'));
      } else if (localStorage.getItem('checkoutId')) {
        setCheckoutId(localStorage.getItem('checkoutId'));
      }
    }

    if (searchParams.get('code')) {
      if (!isSigningUp) {
        handleGoogleSignup();
      }
    }

    if (!isSigningUp && !searchParams.get('code')) {
      setIsLoading(false);
    }
  }, [searchParams, checkoutId, isLoading, navigate, isSigningUp]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data: { token } } = await authService.registerPaidUser({
        firstName,
        lastName,
        email,
        password,
        checkoutId: searchParams.get('session_id')
      });

      localStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || 'An error occurred. Please try again.');
    }
  };

  const handleGoogleSignupClicked = async () => {
    try {
      const { data: { url } } = await authService.getGoogleAuthUrl({ redirectUri: `${APP_URL}/register` });

      window.location.href = url;
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    }
  }

  if (isLoading) {
    return <DashboardLoader message='Signing up...' />;
  }

  return (
    <div className='flex flex-col items-center justify-between flex-grow min-h-screen p-[16px]'>
      <Helmet>
        <title>Register | Restaurant Report</title>
      </Helmet>

      <h1 className='text-[25px] font-semibold gap-1 flex items-center justify-center min-h-[80px] md:min-h-[150px]'>
        <span className='text-white'>Restaurant</span>
        <span className='text-r-blue'>Report</span>
      </h1>

      <div className='flex flex-col items-center justify-center flex-grow w-full'>
        <h2 className='text-center text-[32px] font-medium text-white mb-[24px]'>Sign Up</h2>

        <form onSubmit={handleSubmit} className='flex flex-col w-full md:w-[479px] mb-[50px]'>
          <div className="flex flex-col md:flex-row items-center justify-center w-full gap-[16px] md:gap-[24px]">
            <label className='flex flex-col gap-[16px] mb-[24px] w-full'>
              <span className='text-white font-medium text-[17px]'>First name</span>

              <input
                type='text'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
              />
            </label>

            <label className='flex flex-col gap-[16px] mb-[24px] w-full'>
              <span className='text-white font-medium text-[17px]'>Last name</span>

              <input
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
              />
            </label>
          </div>

          <label className='flex flex-col gap-[16px] mb-[24px]'>
            <span className='text-white font-medium text-[17px]'>Your E-mail</span>

            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <label className='flex flex-col gap-[16px] mb-[16px]'>
            <span className='text-white font-medium text-[17px]'>Password</span>

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <label className='flex flex-col gap-[16px] mb-[16px]'>
            <span className='text-white font-medium text-[17px]'>Confirm password</span>

            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='rounded-md bg-r-gray-light h-[72px] text-white p-[24px] focus:border-r-blue border-r-gray-light'
            />
          </label>

          <button type="submit" disabled={!firstName || !lastName || !password || !confirmPassword || password !== confirmPassword} className='bg-r-blue h-[56px] rounded-md text-white font-semibold mb-[16px] disabled:bg-[#565B6D]'>Sign Up</button>

          <button
            onClick={handleGoogleSignupClicked}
            className='bg-transparent border border-r-blue text-white font-semibold p-2 rounded-md text-center flex items-center justify-center gap-2 h-[56px] mb-[32px]'
          >
            <GoogleLogo /> Sign Up with Google
          </button>

          <p className='text-center text-[#A2A9BF] font-medium'>
            Already have an account? <Link to="/login" className='text-r-blue'>Log In</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default RegistrationForm;
