import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import RestaurantSelect from './RestaurantSelect';
import { RestaurantContext } from '../RestaurantContext';

import { ReactComponent as DocumentIcon } from '../../assets/document-icon.svg';
import { ReactComponent as FoodIcon } from '../../assets/food-icon.svg';
import { ReactComponent as DrinkIcon } from '../../assets/drink-icon.svg';
import { ReactComponent as ServiceIcon } from '../../assets/service-icon.svg';
import { ReactComponent as PriceIcon } from '../../assets/price-icon.svg';
import { ReactComponent as SpyIcon } from '../../assets/spy-icon.svg';

import { startOfMonth, startOfYear, endOfMonth, endOfYear, subMonths } from 'date-fns';

const RestaurantInfo = ({ currentAnalysis, onAnalysisClicked, restaurant, setRestaurant, selectedDateRange, setSelectedDateRange, onDateRangeChange, setSelectedSnapshot, resetLoadedRestaurant, user, isRegeneratingSummaries }) => {
  const {
    restaurantSelectOptions,
    setHasFetchedSnapshots,
    minSelectorDate,
    setMinSelectorDate,
    maxSelectorDate,
    setMaxSelectorDate
  } = useContext(RestaurantContext);

  const navigate = useNavigate();

  const handleAddNewClicked = async () => {
    navigate('/spy');
  }

  const handleRestaurantSelected = (snapshot) => {
    resetLoadedRestaurant();

    const oldestReviewDate = snapshot.restaurant?.oldestReviewDate ? new Date(snapshot.restaurant?.oldestReviewDate) : new Date();
    let latestReviewDate = null;

    if (snapshot.dateCreated) {
      latestReviewDate = new Date(snapshot.dateCreated);
    } else {
      latestReviewDate = snapshot.restaurant?.latestReviewDate ? new Date(snapshot.restaurant.latestReviewDate) : new Date();
    }

    setSelectedDateRange([oldestReviewDate, latestReviewDate]);
    setMinSelectorDate(oldestReviewDate);
    setMaxSelectorDate(latestReviewDate);

    setRestaurant(snapshot.restaurant);
    setHasFetchedSnapshots(false);
    setSelectedSnapshot(snapshot);
  }

  const handleDateHelperSelected = (range, isDisabled) => {
    if (isRegeneratingSummaries || isDisabled) return;

    let startDate = null;
    let endDate = null;

    switch (range) {
      case 'last_month':
        const lastMonth = subMonths(new Date(), 1);
        const lastMonthStart = startOfMonth(lastMonth);
        const lastMonthEnd = endOfMonth(lastMonth);

        startDate = lastMonthStart;
        endDate = lastMonthEnd;
        break;

      case 'this_year':
        startDate = startOfYear(new Date());
        endDate = endOfYear(new Date());
        break;

      case 'last_year':
        startDate = startOfYear(subMonths(new Date(), 12));
        endDate = endOfYear(subMonths(new Date(), 12));
        break;

      case 'all_time':
        startDate = minSelectorDate;
        endDate = maxSelectorDate;
        break;

      default:
        break;
    }

    onDateRangeChange([startDate, endDate]);
  }

  const isLastMonthHelperDisabled = maxSelectorDate < startOfMonth(subMonths(new Date(), 1)) || minSelectorDate > endOfMonth(subMonths(new Date(), 1));
  const isThisYearHelperDisabled = maxSelectorDate < startOfYear(new Date()) || minSelectorDate > endOfYear(new Date());
  const isLastYearHelperDisabled = maxSelectorDate < startOfYear(subMonths(new Date(), 12)) || minSelectorDate > endOfYear(subMonths(new Date(), 12));

  return (
    <div className="p-[16px] md:p-[32px] bg-r-gray-medium flex flex-col">
      {restaurant && (
        <h2 className="text-white text-3xl font-bold text-center mb-[10px]">{restaurant.google.name}</h2>
      )}

      <p className="font-medium text-[14px] md:text-[18px] text-[#9CABC4] mb-[24px] leading-[24px] text-center">
        {restaurant?.google?.address || restaurant?.doordash?.address}
      </p>

      <div className='flex gap-4 mb-3'>
        <RestaurantSelect
          options={restaurantSelectOptions}
          value={restaurantSelectOptions.find(option => option.value === restaurant?._id)}
          spyCredits={user.spyCredits}
          placeholder="Select a restaurant"
          spyPrice="$99"
          onChange={e => handleRestaurantSelected(e)}
        />

        <button onClick={handleAddNewClicked} className='px-2 text-white bg-r-blue font-bold min-w-[60px] rounded-md text-sm flex items-center justify-center'>
          <SpyIcon className="stroke-white fill-white h-8" />
        </button>
      </div>

      <div className='flex flex-col md:flex-row gap-4 mb-4'>
        <div className='h-[39px]'>
          <DateRangePicker
            value={selectedDateRange}
            onChange={onDateRangeChange}
            minDate={minSelectorDate}
            maxDate={maxSelectorDate}
            className="dash-datepicker w-full"
            dayPlaceholder='dd'
            monthPlaceholder='mm'
            yearPlaceholder='yyyy'
            clearIcon={null}
          />
        </div>

        <div className='grid grid-cols-4 flex-grow gap-2'>
          <button
            onClick={e => handleDateHelperSelected('last_month', isLastMonthHelperDisabled)}
            className={`${isLastMonthHelperDisabled ? 'text-gray-500 border-gray-500 cursor-not-allowed' : 'text-gray-300 border border-[#2e425f] hover:bg-r-blue hover:text-white'} h-[39px] md:py-0 border font-semibold rounded-md text-xs flex items-center justify-center transition-all`}
          >
            Last month
          </button>

          <button
            onClick={e => handleDateHelperSelected('this_year', isThisYearHelperDisabled)}
            className={`${isThisYearHelperDisabled ? 'text-gray-500 border-gray-500 cursor-not-allowed' : 'text-gray-300 border border-[#2e425f] hover:bg-r-blue hover:text-white'} h-[39px] md:py-0 border font-semibold rounded-md text-xs flex items-center justify-center transition-all`}
          >
            This year
          </button>

          <button
            onClick={e => handleDateHelperSelected('last_year', isLastYearHelperDisabled)}
            className={`${isLastYearHelperDisabled ? 'text-gray-500 border-gray-500 cursor-not-allowed' : 'text-gray-300 border border-[#2e425f] hover:bg-r-blue hover:text-white'} h-[39px] md:py-0 border font-semibold rounded-md text-xs flex items-center justify-center transition-all`}
          >
            Last year
          </button>

          <button
            onClick={e => handleDateHelperSelected('all_time')}
            className='h-[39px] md:py-0 text-gray-300 border border-[#2e425f] font-semibold rounded-md text-xs flex items-center justify-center hover:bg-r-blue hover:text-white transition-all'
          >
            All time
          </button>
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <div className="flex h-[56px] overflow-x-scroll md:grid md:grid-cols-2 md:h-full gap-[16px]">
          <div
            className={`${currentAnalysis === 'summary' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] col-span-2 rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('summary')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <DocumentIcon className="stroke-white" />
              Summary
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'food' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('food')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <FoodIcon className="fill-white opacity-50" />
              Food
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'drinks' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('drinks')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <DrinkIcon className="fill-white opacity-50" />
              Drinks
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'service' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('service')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <ServiceIcon className="fill-white opacity-50" />
              Service
            </p>
          </div>

          <div
            className={`${currentAnalysis === 'price' ? 'bg-[#18A6C5]' : 'bg-r-gray-light'} min-w-fit md:w-auto px-[18px] rounded-md text-white flex items-center justify-center cursor-pointer`}
            onClick={e => onAnalysisClicked('price')}
          >
            <p className="flex items-center gap-[8px] font-semibold">
              <PriceIcon className="stroke-white" />
              Price
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestaurantInfo;
