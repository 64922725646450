import { useContext } from "react";

import { AuthContext } from "./AuthContext";

import PlansNew from "./UserPlans/PlansNew";

import apiService from "../services/apiService";

const SubscriptionExpired = () => {
  const { user } = useContext(AuthContext);

  const handleSelectPlan = async (priceId) => {
    const response = await apiService.updateCanceledSubscription({
      userId: user._id,
      priceId
    });

    window.location.href = response.data.url;
  }

  return (
    <div className="w-[1000px] max-h-[90%] bg-[#090A17] p-[32px] border border-[#36c5fe] rounded-lg overflow-scroll">
      <h1 className="mb-3 text-white text-xl font-bold text-center">
        Subscription Expired
      </h1>

      <p className="text-white text-center font-semibold mb-8">
        Renew or upgrade your subscription to continue using RestaurantReport
      </p>

      <PlansNew
        onPlanClicked={handleSelectPlan}
      />
    </div>
  );
};

export default SubscriptionExpired;
