import axios from 'axios';

import { API_URL } from "../constants";

const axiosInstance = axios.create({
  baseURL: API_URL
});

const getGoogleAuthUrl = async ({ redirectUri }) => {
  const response = await axiosInstance.post(`/auth/google`, { redirectUri });

  return response;
};

const registerPromoUser = async ({ email, firstName, lastName, password }) => {
  const response = await axiosInstance.post(`/auth/promo-signup`, { email, firstName, lastName, password });

  return response;
}

const registerPaidUser = async ({ email, firstName, lastName, password, checkoutId }) => {
  const response = await axiosInstance.post(`/auth/register`, {
    firstName,
    lastName,
    email,
    password,
    stripeCheckoutId: checkoutId
  });

  return response;
};

const googleRegisterPaidUser = async ({ code, checkoutId }) => {
  const response = await axios.post(`${API_URL}/auth/google/paid-signup`, { code, checkoutId });

  return response;
}

const googleRegisterPromoUser = async ({ code }) => {
  const response = await axios.post(`${API_URL}/auth/google/promo-signup`, { code });

  return response;
}

const loginUser = async ({ email, password }) => {
  const response = await axiosInstance.post(`/auth/login`, { email, password });

  return response;
}

const googleLoginUser = async ({ code }) => {
  const response = await axios.post(`${API_URL}/auth/google/login`, { code });

  return response;
}

const authService = {
  getGoogleAuthUrl,
  loginUser,
  googleLoginUser,
  registerPromoUser,
  googleRegisterPromoUser,
  registerPaidUser,
  googleRegisterPaidUser
}

export default authService;
